// => 客户管理: CustomerSteward
import http from "./http"
const newUrl = 'https://pay.gxb.cn/api'
/**  @namespace: {客户列表},  @param: { token }  */
export const $apiCustomerList = (data) => http({ method: "get", url: "/Web/manage/user", params: data })
//新客户列表 用户自主添加客户
export const $apiNewCustomerList = (data) => http({ method: "post", url: newUrl+"/company/customer/list",data })
// 添加客户
export const $apiCustomerAdd = (data) => http({ method: "post", url: newUrl+"/company/customer/edit",data })
// 修改客户资料
export const $apiCustomerEdit = (data) => http({ method: "post", url: newUrl+"/company/customer/edit/"+data.id,data })


